import React from 'react';
import logo from './logo_transparent_white.png';
import './App.scss';

function App() {
  return (
    <div className="intro">
      <img className="logo" src={logo} alt="JustCodeIT logo" />
      <h1>JustCodeIT</h1>
      <h2>You name it... We code it!</h2>
    </div>
  );
}

export default App;
